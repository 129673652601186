<template>
  <v-card>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/approval-templates">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- start of toolbar 2 -->
    <v-toolbar color="secondary" flat dense>
      <v-btn
        v-show="pageMode == 'create'"
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="loader"
      >
        <v-icon left dark>mdi-content-save</v-icon>Save
      </v-btn>
      <v-btn
        v-show="pageMode == 'view'"
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="loader"
      >
        <v-icon left dark>mdi-content-save</v-icon>Update
      </v-btn>
    </v-toolbar>
    <!-- end of toolbar 2 -->
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="4">
            <v-text-field
              dense
              outlined
              v-model="record.Name"
              label="Name"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              dense
              outlined
              v-model="record.Remarks"
              label="Description"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-radio-group outlined v-model="record.Active" row>
              <v-radio label="Active" value="Y"></v-radio>
              <v-radio label="In Active" value="N"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <!-- tabs start here -->
            <v-tabs background-color="tabToolbar">
              <v-tab>Originator</v-tab>
              <v-tab>Documents</v-tab>
              <v-tab>Stages</v-tab>
              <v-tab>Terms</v-tab>

              <v-tab-item>
                <v-row>
                  <v-col cols="6">
                    <v-btn color="flatButton" text @click="openDialog">
                      <v-icon>mdi-plus</v-icon>Add Originator
                    </v-btn>
                  </v-col>

                  <v-col cols="12">
                    <v-data-table
                      hide-default-footer
                      :headers="headers"
                      :items="authorizers"
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row dense>
                  <v-col cols="4" v-for="doc in documents" v-bind:key="doc.id">
                    <v-checkbox
                      v-model="docs"
                      :true-value="doc.ObjectId"
                      :value="doc.ObjectID"
                      :label="`${doc.DocumentName}`"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="6">
                    <v-btn color="flatButton" text @click="stageModal">
                      <v-icon>mdi-plus</v-icon>Add Stage
                    </v-btn>
                  </v-col>

                  <v-col cols="12">
                    <v-data-table
                      hide-default-footer
                      :headers="stageHeaders"
                      :items="selectedStages"
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row dense class="mt-2">
                  <v-col cols="12">
                    <div>Launch Approval Process:</div>
                    <v-radio-group v-model="record.Conds" row>
                      <v-radio label="Always" value="N"></v-radio>
                      <v-radio label="When the Following Applies" value="Y"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row dense v-if="record.Conds == 'Y'">
                  <v-col small cols="12">
                    <v-row dense v-for="(row, index) in rows" :key="index">
                      <v-col cols="3">
                        <v-autocomplete
                          dense
                          outlined
                          v-model="row.CondId"
                          :items="termsArray"
                          label="Term"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                          dense
                          outlined
                          v-model="row.opCode"
                          :items="ratioArray"
                          label="Ratio"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          dense
                          outlined
                          v-model="row.opValue"
                          label="Value"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-btn small dark color="red" @click="removeRow(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-btn color="primary" small class="mt-1" @click="addRow"
                      >Add Term
                    </v-btn>
                  </v-col>
                  <br />
                  <v-col cols="12">Terms Based on User Queries </v-col>
                  <br />
                  <v-col v-for="(QueryRow, index) in QueryRows" :key="index" cols="12">
                    <v-row>
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          outlined
                          :items="Query"
                          item-text="QName"
                          item-value="QCode"
                          label="Query"
                          v-model="QueryRow.QueryId"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-btn small dark color="red" @click="removeQueryRow(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-btn color="primary" small class="mt-1" @click="addQueryRow"
                      >Add Query
                    </v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
            <!-- end of tabs -->
          </v-col>
        </v-row>
      </v-form>

      <!-- user dialog -->
      <v-dialog v-model="userDialog" max-width="550px">
        <v-card>
          <v-toolbar color="primary" dense dark>
            <v-toolbar-title>Select Originator</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="userDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  @change="addUser"
                  dense
                  outlined
                  v-model="newUser"
                  :items="users"
                  label="Originators"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- user dialog -->

      <!-- stage dialog -->
      <v-dialog v-model="stageDialog" max-width="550px">
        <v-card>
          <v-toolbar color="primary" dense dark>
            <v-toolbar-title>Select Approval Stage</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="stageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  @change="addStage"
                  v-model="newStage"
                  :items="approvalStages"
                  label="Approval Stages"
                  item-text="Name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- stage dialog -->

      <snackbar ref="snackbar"></snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
    },
    authUsers: {
      type: Array,
    },
    setStages: {
      type: Array,
    },
    setDocs: {
      type: Array,
    },
    setTerms: {
      type: Array,
    },
    setQueries: {
      type: Array,
    },
    pageMode: {
      type: String,
    },
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      record: {},
      userDialog: false,
      stageDialog: false,
      newUser: "",
      newStage: null,
      loader: false,
      users: [],
      docs: [],
      authorizers: [],
      documents: [],
      selectedStages: [],
      approvalStages: [],
      Query: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Department", value: "department" },
      ],
      stageHeaders: [
        { text: "Name", value: "Name" },
        { text: "Description", value: "Remarks" },
      ],
      termsArray: [
        { value: 0, text: "Undefined Type" },
        { value: 10, text: "Counted Quantity" },
        { value: 11, text: "Variance" },
        { value: 12, text: "Variance %" },
        { value: 1, text: "Deviation from Credit Limit" },
        { value: 2, text: "Deviation from Commitment" },
        { value: 3, text: "Gross Profit %" },
        { value: 4, text: "Discount %" },
        { value: 5, text: "Deviation from Budget" },
        { value: 6, text: "Total Document" },
        { value: 7, text: "Quantity" },
        { value: 8, text: "Item Code" },
        { value: 9, text: "Total" },
      ],
      ratioArray: [
        { value: 0, text: "Undefined Type" },
        { value: 1, text: "Greater Than" },
        { value: 2, text: "Greater or Equal" },
        { value: 3, text: "Less Than" },
        { value: 4, text: "Less or Equal" },
        { value: 5, text: "Equal" },
        { value: 6, text: "Does not Equal" },
        { value: 7, text: "In Range" },
        { value: 8, text: "Not in Range" },
      ],
      rows: [{ CondId: "", opCode: "", opValue: "" }],
      QueryRows: [{ QueryId: null }],
    };
  },
  watch: {
    initial: {
      handler: "setInitial",
      immediate: true,
    },
    authUsers: {
      handler: "setAuthorizers",
      immediate: true,
    },
    setStages: {
      handler: "setApprovalStages",
      immediate: true,
    },
    setDocs: {
      handler: "setDocuments",
      immediate: true,
      default: () => [],
    },
    setTerms: {
      handler: "setApprovalTerms",
      immediate: true,
    },
    setQueries: {
      handler: "setApprovalQueries",
      immediate: true,
    },
  },
  methods: {
    setInitial(val) {
      this.record = { ...val };
    },
    setAuthorizers(val) {
      this.authorizers = [...val];
    },
    setApprovalStages(val) {
      this.selectedStages = [...val];
    },
    setDocuments(val) {
      //this.docs = [...val];
      this.docs = val.map((record) => record.id);
    },
    setApprovalTerms(val) {
      this.rows = [...val];
    },
    setApprovalQueries(val) {
      this.QueryRows = [...val];
    },
    openDialog() {
      this.newUser = "";
      this.userDialog = true;
    },
    stageModal() {
      this.newStage = "";
      this.stageDialog = true;
    },
    addRow() {
      this.rows.push({ CondId: "", opCode: "", opValue: "" });
    },
    removeRow(index) {
      this.rows.splice(index, 1);
    },
    addQueryRow() {
      this.QueryRows.push({ QueryId: null });
    },
    removeQueryRow(index) {
      this.QueryRows.splice(index, 1);
    },
    getQuery() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", "/query_manager")
        .then((res) => {
          self.Query = res.ResponseData;
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    sendData() {
      // users
      const users = this.authorizers;
      let ids = users.reduce((op, { id }) => {
        if (typeof id !== "undefined") {
          op.push(id);
        }
        return op;
      }, []);

      // approval stages
      const stages = this.selectedStages;
      const wtm2 = [];
      for (const stage of stages) {
        let details = {
          WstCode: stage.id,
          SortId: 1,
          Remarks: stage.Remarks,
        };
        wtm2.push(details);
      }
      const wtm4 = this.rows.map((row) => ({
        CondId: row.CondId,
        opCode: row.opCode,
        opValue: row.opValue,
      }));

      const data = this.record;
      data.wtm1 = ids;
      data.wtm2 = wtm2;
      data.wtm3 = this.docs;
      data.wtm4 = wtm4;
      data.wtm5 = this.QueryRows;
      console.log(data);
      this.$emit("data", data);
      this.loader = true;
    },
    addUser() {
      if (this.newUser == null) {
        this.$refs.snackbar.show("Please select user!", "red");
      } else {
        const id = this.newUser;
        const user = this.authorizers.find((record) => {
          return record.id === id;
        });

        const addedUser = this.users.find((record) => {
          return record.id === id;
        });
        if (user == undefined) {
          this.authorizers.push(addedUser);
          this.newUser = "";
          this.$refs.snackbar.show(`${addedUser.name} added`, "green");
        } else {
          this.$refs.snackbar.show(
            `${user.name} already exist in the originators list`,
            "red"
          );
          this.newUser = "";
        }
        this.userDialog = false;
      }
    },
    addStage() {
      if (this.newStage == null) {
        this.$refs.snackbar.show("Please select user!", "red");
      } else {
        const id = this.newStage;
        const stage = this.selectedStages.find((record) => {
          return record.id === id;
        });

        const addedStage = this.approvalStages.find((record) => {
          return record.id === id;
        });
        if (stage == undefined) {
          this.selectedStages.push(addedStage);
          this.newStage = null;
          this.$refs.snackbar.show(`${addedStage.Name} added`, "green");
        } else {
          this.$refs.snackbar.show(`${stage.Name} already exist in the list`, "red");
          this.newStage = null;
        }

        this.stageDialog = false;
      }
    },
    getUsers() {
      const self = this;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.users = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDocs() {
      const self = this;
      this.$store
        .dispatch("get", `/doc_model`)
        .then((res) => {
          self.documents = res;
          console.log(self.documents);
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getApprovalStages() {
      const self = this;
      this.$store
        .dispatch("get", `/approval_stages`)
        .then((res) => {
          self.approvalStages = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getUsers();
    this.getDocs();
    this.getApprovalStages();
    this.getQuery();
  },
};
</script>

<style lang="scss" scoped></style>
