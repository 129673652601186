<template>
  <v-container fluid>
    <editor
      :initial="dataItem"
      :setDocs="setDocs"
      :authUsers="authUsers"
      :setStages="setStages"
      @data="save"
      :pageMode="pageMode"
      :setTerms="setTerms"
      :setQueries="setQueries"
      :pageTitle="pageTitle"
    ></editor>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      pageMode: "view",
      pageTitle: "Approval Template",
      dataItem: {},
      setQueries: [],
      setTerms: [],
      setDocs: [],
      authUsers: [],
      setStages: [],
    };
  },
  watch: {
    "$route.params.id": {
      handler: "templateById",
      immediate: true,
    },
  },
  methods: {
    templateById(id) {
      const self = this;
      this.$store
        .dispatch("get", `/approval_templates/${id}`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.dataItem = res.ResponseData;

            const wtm1 = res.ResponseData.wtm1;
            const users = wtm1.map((record) => {
              return { id: record.users.id, name: record.users.name };
            });
            self.authUsers = users;

            const wtm2 = res.ResponseData.wtm2;
            const stages = wtm2.flatMap((record) =>
              record.stages.map((stage) => ({
                id: stage.id,
                Name: stage.Name,
                Remarks: stage.Remarks,
              }))
            );
            self.setStages = stages;

            const wtm3 = res.ResponseData.wtm3;
            const docs = wtm3.map((record) => {
              return { id: record.TransType };
            });
            self.setDocs = docs;

            const wtm4 = res.ResponseData.wtm4;
            const terms = wtm4.map((record) => {
              return {
                CondId: record.CondId,
                opCode: record.opCode,
                opValue: record.opValue,
              };
            });
            self.setTerms = terms;

            const wtm5 = res.ResponseData.wtm5;
            const Queries = wtm5.map((record) => {
              return {
                QueryId: record.QueryId,
              };
            });
            self.setQueries = Queries;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      const id = this.dataItem.id;
      console.log(id, "id");
      const url = `/approval_templates/${id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.$router.push("/approval-templates");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
